// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);

  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);

    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
      (element_top_position <= window_bottom_position)) {
      $element.addClass('active');
    }
  });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

  $('.js-replace-img').each(function() {

    var src = $(this).find("img").attr("src");
    $(this).css("background-image", "url(" + src + ")");
  });
}

jQuery(function() {
  if ($(".js-replace-img")) {
    replaceSrc();
  }
});

// SCROLL FADE HEADER - Slideshow

var viewHeight = window.innerHeight;
var header = $(".slideshow");
$(window).scroll(function(event) {
  var scroll = $(window).scrollTop();
  var calcul = (scroll / 500);
  $(".slideshow").css("opacity", 1 - calcul);
});


(function() {

  var s = {

    menuState: function() {

      $(this).toggleClass("cross");
      $(".main-nav-wrapper").toggleClass("open");

    },

    headerHeight: function() {

      if ($(window).scrollTop() > 0) {

        return $('.main-header').height();
      } else {

        return $('.main-header').height() - 24;
      }
    },

    headerPosition: function() {

      var top = $(window).scrollTop();

      if (top > 72) {

        $(".main-header").addClass("min");
        $(".sub-nav").addClass("min");
      } else {

        $(".main-header").removeClass("min");
        $(".sub-nav").removeClass("min");
      }
    },

    events: function() {
      $(window).scroll(this.headerPosition);
      $("#btnMobile").on("click", this.menuState);
    },

    init: function() {

      this.headerPosition();
      this.events();
    }

  };

  s.init();

})();


$("ul > li.menu-item-has-children > a").click(function(e) {
  event.preventDefault();
  $("ul li ul").removeClass("open");
  $(this).next(".sub-menu").toggleClass("open");
});

$("body  section").mouseover(function(e) {
  $("ul li ul").removeClass("open");
});

$("ul > li.menu-item-has-children > a").click(function(e) {
  event.preventDefault();
  $("ul li ul").removeClass("open");
  $(this).next(".sub-menu").toggleClass("open");
});

$("#btnMobile").click(function(e) {
  event.preventDefault();
  $("ul li ul").removeClass("open");
});




function sticky_relocate() {
  var window_top = $(window).scrollTop();
  var footer_top = $("#main-footer").offset().top;
  var div_top = $('.aside_box').offset().top;
  var div_height = $(".aside").height();

  var padding = 150;

  if (window_top + div_height > footer_top - padding)
    $('.aside').css({
      top: (window_top + div_height - footer_top + padding) * -1,
      visibility: "hidden",
    });


  else if (window_top > div_top - padding) {
    $('.aside').addClass('aside_position');
    $('.aside').css({
        top: 100,
        visibility: "visible",
      });

  } else {
    $('.aside').removeClass('aside_position');
  }
}


jQuery(function() {

  if ($(".aside").length) {
    $(window).scroll(sticky_relocate);
    sticky_relocate();
  }
});


$("#btn_subnav").click(function(e) {
  // event.preventDefault();
  $("#btn_subnav").toggleClass("active_btn");
  $(".aside").toggleClass("sub_nav-active");
});

// slick
$(document).ready(function(){
  var totalSlidesNews = $('.news-grid .content-wrapper article').length;
  console.log(totalSlidesNews);

$('.news-grid .content-wrapper').slick({
  dots: false,
  variableWidth: false,
  arrows: true,
  infinite: true,
  speed: 300,
  slidesToShow: totalSlidesNews >= 3 ? 3 : totalSlidesNews,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: totalSlidesNews >= 2 ? 2 : totalSlidesNews,
        slidesToScroll: 2,
        infinite: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: totalSlidesNews >= 2 ? 2 : totalSlidesNews,
        slidesToScroll: 2,
        dots: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: totalSlidesNews >= 1 ? 1 : totalSlidesNews,
        slidesToScroll: 1,
        dots: false,
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

$('.product-grid .content .products').slick({
  dots: false,
  variableWidth: false,
  arrows: true,
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

$('.related .products').slick({
  dots: false,
  variableWidth: true,
  arrows: true,
  infinite: true,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

$('.article-home .content .row').slick({
  dots: false,
  variableWidth: false,
  arrows: true,
  infinite: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

});
